import React from 'react';
import goFunction from '../logic'

function About() {

    return (
    <div>
    <h3>I'm Zane a front end and aspiring web3 developer, dance teacher, and humanist. 
    Originally from the Bay Area of California, I'm now living and thriving in Berlin, Germany.

        </h3>
        <h3>
            This Go game I designed to help me practice Javascript, CSS, React, etc and other skills. I'm constantly iterating, and implementing
            new concepts.   
        </h3>
        <h3>    
            I find the web3 and decentralized tech stack to be super interesting,  and I have a particular interest in tokenomics
            and regenerative finance. I'm interested in how we can create recursive positive externalities to create
            a better more sustainable world, and an economy that works for the people, and not a few parasitic elite. I spend quite a bit of time
            thinking about cash-flows as primatives, and how to bridge the gap between the metaverse and
            the physical world.
        </h3>
        <h3>    
            A couple of projects that I like are KlimaDAO, for carbon removal, Circles for, for UBI/local currencies and Influence, a blockchain
            native space MMO game. I'm currently working for a dance school, 'Let it Swing' <a className="info" href='https://www.letitswing.tv'>LetItSwing.tv</a> , Coffee Circle a specialty coffee company invested 
            in ecological development in countries of origin, and going to school at 42 Berlin to study programming from the ground up.
        </h3>

        <h3>   
            Please reach out if you want to collaborate, or if there is something interesting I should know about.
            web3noob@gmail.com
        </h3>
        <h3>    
    </h3>
    </div>
    )
  }

  export default About